import React, { useState } from "react";
import "../../index.css";

const RenameInputPopup = ({ onClose, onSubmit, type, name }) => {
  const [newName, setNewName] = useState(name);
  const pattern = /^[a-zA-Z0-9_-]+\.?[a-zA-Z0-9]*$/;

  const handleSubmit = () => {
    onSubmit(newName);
    onClose();
  };

  const isInputValid = pattern.test(newName);

  return (
    <div className="name-input-popup">
      <div className="name-input-content">
        <h3 style={{ color: "black" }}>Rename {type}</h3>
        <input
          type="text"
          pattern="^[a-zA-Z0-9_-]*\.?[a-zA-Z0-9]*$"
          title="Only alphanumeric characters and at most one period"
          maxLength={50}
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder={`Enter ${type} Name`}
          style={{}}
        />
        <div className="name-input-buttons" style={{ margin: "10px" }}>
          <button onClick={handleSubmit} disabled={!isInputValid}>
            Confirm
          </button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default RenameInputPopup;
