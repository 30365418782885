import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../../authentication.tsx";
import Loading from "../Loading/Loading.tsx";
import "../../index.css";
import ReactGA from "react-ga4";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripePayment: React.FC = () => {
  const { getCookie } = useAuth();
  const [loading, setLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState("");
  const [canceled, setCanceled] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<Date | null>(
    null
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      alert("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      alert(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  // Check if the customer is already subscribed
  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await fetch(
          "https://backend.chipworks.app/customerapi/auth/check-subscription/",
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": getCookie("csrftoken"),
            },
            credentials: "include",
          }
        );
        const data = await response.json();
        console.log(data);
        setLoading(false);
        if (data.subscription_active) {
          setSubscribed(true);
          setSubscriptionID(data.subscription_id);
          console.log("Customer is already subscribed");
          if (data.cancel_at_period_end) {
            setCanceled(true);
            console.log("Customer has canceled subscription");
            console.log(data.current_period_end);
            setSubscriptionEndDate(new Date(data.current_period_end * 1000));
          }
        }
      } catch (error) {
        console.error("Error fetching account:", error);
      }
    };
    fetchAccount();
  }, [getCookie]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = async (event) => {
    ReactGA.event({
      category: "User",
      action: "clicked_subscribe_button",
    });

    const stripe = await stripePromise;
    const response = await fetch(
      "https://backend.chipworks.app/customerapi/auth/create-checkout-session/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      const text = await response.text();
      console.log(text);
    }

    const session = await response.json();

    const result = await stripe.redirectToCheckout({
      sessionId: session.sessionId,
    });

    if (result.error) {
      // Handle error here
      console.log(result.error.message);
    }
  };

  const handleClickCancel = async (event) => {
    ReactGA.event({
      category: "User",
      action: "clicked_cancel_subscription_button",
    });

    const response = await fetch(
      "https://backend.chipworks.app/customerapi/auth/cancel-subscription/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        credentials: "include",
        body: JSON.stringify({
          subscription_id: subscriptionID,
        }),
      }
    );

    if (!response.ok) {
      const text = await response.text();
      console.log(text);
    }

    const data = await response.json();
    console.log(data.current_period_end);
    setSubscriptionEndDate(new Date(data.current_period_end * 1000));
    setCanceled(true);
    console.log(data);
  };

  const handleClickResubscribe = async (event) => {
    ReactGA.event({
      category: "User",
      action: "clicked_resubscribe_button",
    });

    const response = await fetch(
      "https://backend.chipworks.app/customerapi/auth/uncancel-subscription/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        credentials: "include",
        body: JSON.stringify({
          subscription_id: subscriptionID,
        }),
      }
    );

    if (!response.ok) {
      const text = await response.text();
      console.log(text);
    }

    const data = await response.json();
    console.log(data);
    setCanceled(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "400px",
        height: "112px",
        borderTop: "1px solid #00c2d1",
        justifyContent: "space-between",
      }}
    >
      <h3>ChipWorks Basic</h3>
      <p>Monthly Subscription: $9.99</p>
      <p>
        Access to all ChipWorks features, including the ability to run the flow
        on our servers.
      </p>
      {loading ? (
        <Loading />
      ) : subscribed && !canceled ? (
        <div>
          <p>You are already subscribed. Cancel Anytime.</p>
          <button role="link" onClick={handleClickCancel} style={buttonStyle}>
            Cancel Subscription
          </button>
        </div>
      ) : subscribed && canceled ? (
        <div>
          <p>
            You have canceled your subscription. Your access will end on{" "}
            {subscriptionEndDate?.toDateString()}. You may resubscribe at any
            time.
          </p>
          <button
            role="link"
            onClick={handleClickResubscribe}
            style={buttonStyle}
          >
            Resubscribe
          </button>
        </div>
      ) : (
        <button role="link" onClick={handleClick} style={buttonStyle}>
          Subscribe
        </button>
      )}
    </div>
  );
};

const buttonStyle = {
  height: "36px",
  background: "#00c2d1",
  borderRadius: "4px",
  color: "white",
  border: "0",
  fontWeight: 600,
  cursor: "pointer",
  transition: "all 0.2s ease",
  boxShadow: "0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)",
};

export default StripePayment;
