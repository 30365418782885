import React from "react";
import Editor from "@monaco-editor/react";
import Loading from "../Loading/Loading.tsx";
import "../../index.css";

const CodeEditor: React.FC<{
  content: string;
  fileName: string;
  language: string;
  onChange: (value: string) => void;
}> = ({ content, fileName, language, onChange }) => {
  return (
    <div className="code-editor">
      <Editor
        loading={<Loading />}
        language={language}
        defaultValue={content}
        path={fileName}
        theme="vs-dark"
        onChange={onChange}
        options={{ selectOnLineNumbers: true }}
        automaticLayout={true}
      />
    </div>
  );
};

export default CodeEditor;
