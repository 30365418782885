import React, { useState, useEffect, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "./authentication.tsx";
import {
  FileStructureContext,
  FileNode,
} from "./components/FileExplorer/FileStructureContext.tsx";
import NavBar from "./components/NavBar/NavBar.tsx";
import FileExplorer from "./components/FileExplorer/FileExplorer.tsx";
import CodeEditor from "./components/CodeEditor/CodeEditor.tsx";
import MobileCodeEditor from "./components/CodeEditor/MobileCodeEditor.tsx";
import Terminal from "./components/Terminal.tsx";
import ProjectCreationPopup from "./components/NewProjectPopup.tsx";
import PopupMessage from "./components/PopupMessage.tsx";
import ReactGA from "react-ga4";
import "./index.css";

interface FileState {
  fileNode: FileNode;
  language: string;
}

const EditorPage: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [projectName, setProjectName] = useState<string>("");
  const location = useLocation();
  const [activeFileState, setActiveFileState] = useState<FileState | null>(
    null
  );

  const [jobStatus, setJobStatus] = useState("NOJOB");
  const [flowLoading, setFlowLoading] = useState(false);
  const [showTerminal, setShowTerminal] = useState(false);
  const [taskID, setTaskID] = useState(""); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [socketUrl, setSocketUrl] = useState("");
  const [streamToken, setStreamToken] = useState("");

  const [showPopupMessage, setShowPopupMessage] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const { updateFileStructure } = useContext(FileStructureContext);
  const { getCookie } = useAuth();

  const isMobile = useMemo(() => {
    const userAgent = navigator.userAgent || window.opera;
    const platform = navigator.platform;

    // Check for iOS devices
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    // Check for Android devices
    if (/android/i.test(userAgent)) {
      return true;
    }

    // Additional check for iPads running iPadOS (which mimics macOS)
    if (
      /MacIntel/.test(platform) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 1
    ) {
      return true;
    }

    return false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isNewProject = queryParams.get("newProject");
    setShowPopup(isNewProject === "true");
    if (isNewProject !== "true") {
      setProjectName(queryParams.get("project") || "");
    }
  }, [location]);

  // At first, get the information about this project from the server
  useEffect(() => {
    if (projectName) {
      fetch(
        `https://backend.chipworks.app/customerapi/projects/info/${projectName}/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setJobStatus(data.job_running);
          setTaskID(data.last_job_id);
          setSocketUrl(data.last_job_socket);
          setStreamToken(data.last_job_socket_token);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [projectName, getCookie]); // eslint-disable-line react-hooks/exhaustive-deps

  // If the job status is queued or started, poll the server for job status updates periodically
  useEffect(() => {
    if (jobStatus === "QUEUED" || jobStatus === "STARTED") {
      const interval = setInterval(() => {
        fetch(
          `https://backend.chipworks.app/customerapi/flowservers/jobstatus/${taskID}/`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": getCookie("csrftoken"),
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            setJobStatus(data.status);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [jobStatus, taskID, getCookie]);

  const deleteNewProjectQueryParam = (project_name: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("newProject");
    queryParams.set("project", project_name);
    window.history.replaceState(
      {},
      "",
      `${location.pathname}?${queryParams.toString()}`
    );
  };

  const handleCreateProject = async (
    project_name: string,
    project_description: string
  ) => {
    try {
      const response = await fetch(
        "https://backend.chipworks.app/customerapi/projects/create/",
        {
          method: "POST",
          body: JSON.stringify({ project_name, project_description }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        alert("Failed to create project, try again.");
        const text = await response.text();
        console.log(text);
      } else {
        setProjectName(project_name);
        setShowPopup(false);
        deleteNewProjectQueryParam(project_name);
      }
    } catch (error) {
      alert("Failed to create project, try again.");
    }
  };

  const getLanguageFromFileName = (fileName: string): string => {
    const extension = fileName.split(".").pop();

    switch (extension) {
      case "js":
        return "javascript";
      case "ts":
        return "typescript";
      case "json":
        return "json";
      case "py":
        return "python";
      case "md":
        return "markdown";
      case "css":
        return "css";
      case "html":
        return "html";
      case "go":
        return "go";
      case "java":
        return "java";
      case "c":
        return "c";
      case "cpp":
        return "cpp";
      case "cs":
        return "csharp";
      case "rb":
        return "ruby";
      case "php":
        return "php";
      case "v":
        return "verilog";
      case "sv":
        return "systemverilog";
      case "bash":
        return "shell";
      default:
        return "plaintext"; // Default language
    }
  };

  const handleFileSelect = (fileNode: FileNode) => {
    const language = getLanguageFromFileName(fileNode.name);
    const fileExists = activeFileState?.fileNode.path === fileNode.path;

    if (!fileExists) {
      const newFile = {
        fileNode,
        language,
        isActive: true,
        isDirty: false,
      };
      setActiveFileState(newFile);
    }
  };

  const handleEditorChange = (newContent: string) => {
    if (!activeFileState) {
      return;
    }
    updateFileStructure(activeFileState.fileNode.path, newContent);
  };

  const checkSubscription = async () => {
    console.log("check subscription");
    setFlowLoading(true);

    try {
      const response = await fetch(
        "https://backend.chipworks.app/customerapi/auth/check-subscription/",
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.subscription_active || data.is_employee) {
          runFlow();
        } else {
          setFlowLoading(false);
          setPopupMessage(
            "You need to subscribe to ChipWorks to run the flow.\nManage your subscription in the account settings."
          );
          setShowPopupMessage(true);
        }
      } else {
        const data = await response.json();
        console.log(data);
        setPopupMessage(data.error);
        setShowPopupMessage(true);
        setFlowLoading(false);
      }
    } catch (error) {
      console.log(error);
      setFlowLoading(false);
    }
  };

  const runFlow = async () => {
    console.log("run flow");

    ReactGA.event({
      category: "Flow",
      action: "run_flow",
    });

    try {
      const response = await fetch(
        "https://backend.chipworks.app/customerapi/flowservers/startjob/",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({ project: projectName }),
        }
      );

      if (response.ok) {
        console.log("run flow ok");
        const data = await response.json();
        console.log(data);
        setTaskID(data.task_id);
        setSocketUrl(data.socket_url);
        setStreamToken(data.group_token);
        setJobStatus("QUEUED");
      } else {
        const data = await response.json();
        console.log(data);
        setPopupMessage(data.message);
        setShowPopupMessage(true);
      }
    } catch (error) {
      console.log(error);
      alert("Failed to run flow, try again later.");
    }
    setFlowLoading(false);
  };

  const cancelJob = async () => {
    console.log("cancel job");

    ReactGA.event({
      category: "Flow",
      action: "cancel_job",
    });

    try {
      const response = await fetch(
        `https://backend.chipworks.app/customerapi/flowservers/killjob/${taskID}/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
        }
      );

      if (response.ok) {
        console.log("cancel job ok");
        const data = await response.json();
        console.log(data);
        setJobStatus("FAILED");
        setShowTerminal(false);
      } else {
        console.log("cancel job not ok");
        const data = await response.json();
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewOutput = () => {
    console.log("view output");
    setShowTerminal(true);
  };

  const handleCloseTerminal = () => {
    setShowTerminal(false);
  };

  // Memoize the editor component to prevent unnecessary re-renders
  const EditorComponent = useMemo(() => {
    return isMobile ? MobileCodeEditor : CodeEditor;
  }, [isMobile]);

  return (
    <div className="app-container">
      <NavBar
        projectName={projectName}
        runFlow={checkSubscription}
        cancelJob={cancelJob}
        viewOutput={viewOutput}
        jobStatus={jobStatus}
        flowLoading={flowLoading}
      />
      <ProjectCreationPopup isOpen={showPopup} onCreate={handleCreateProject} />
      <PopupMessage
        isOpen={showPopupMessage}
        message={popupMessage}
        closePopup={() => setShowPopupMessage(false)}
      />
      <div className="main-area">
        <FileExplorer
          onFileSelect={handleFileSelect}
          projectName={projectName}
          jobStatus={jobStatus}
        />
        <div className="editor-terminal-container">
          {activeFileState ? (
            <EditorComponent
              key={activeFileState.fileNode.path}
              content={
                activeFileState.fileNode.content?.length
                  ? activeFileState.fileNode.content
                  : ""
              }
              fileName={activeFileState.fileNode.name}
              language={activeFileState.language}
              onChange={handleEditorChange}
            />
          ) : (
            <EditorComponent
              content="Welcome to your new project! Open the README.md file to get started."
              fileName=""
              language="plaintext"
              onChange={() => {}}
            />
          )}
        </div>
        <Terminal
          isVisible={showTerminal}
          socketUrl={socketUrl}
          streamToken={streamToken}
          onClose={handleCloseTerminal}
          onCancel={cancelJob}
        />
      </div>
    </div>
  );
};

export default EditorPage;
