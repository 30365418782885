import React, { useState, useEffect, useContext } from "react";
import { FileStructureContext, FileNode } from "./FileStructureContext.tsx";
import FileUploadPopup from "./FileUploadPopup.tsx";
import FileExplorerToolbar from "./FileExplorerToolbar.tsx";
import NameInputPopup from "./NameInputPopup.tsx";
import RenameInputPopup from "./RenameInputPopup.tsx";
import RelativeLoading from "../Loading/RelativeLoading.tsx";
import { useAuth } from "../../authentication.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFolderOpen } from "@fortawesome/free-solid-svg-icons";

const FileExplorer: React.FC<{
  onFileSelect: (fileNode: FileNode) => void;
  projectName: string;
  jobStatus: string;
}> = ({ onFileSelect, projectName, jobStatus = "NOJOB" }) => {
  const { getCookie } = useAuth();

  const [loading, setLoading] = useState(true);

  const [draggedItem, setDraggedItem] = useState(null);
  const [showNameInput, setShowNameInput] = useState(false);
  const [showRenameInput, setShowRenameInput] = useState(false);
  const [type, setType] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [renamingNode, setRenamingNode] = useState<FileNode | null>(null);
  const [addingNode, setAddingNode] = useState<FileNode | null>(null);

  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const { fileStructure, setFileStructure, setProjectName } =
    useContext(FileStructureContext);

  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    node: FileNode | null;
    show: boolean;
  }>({ x: 0, y: 0, node: null, show: false });

  useEffect(() => {
    console.log("Setting project name:", projectName);
    setProjectName(projectName);
  }, [projectName, setProjectName]);

  useEffect(() => {
    // Effect for fetching files
    const fetchFiles = async () => {
      try {
        const response = await fetch(
          `https://backend.chipworks.app/customerapi/projects/files/${projectName}/`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": getCookie("csrftoken"),
            },
            credentials: "include",
          }
        );
        const data = await response.json();
        const files = data.files;
        console.log("Files:", files);

        const fileObjects = files.map((file) => {
          const localPath = file.split("/").slice(4).join("/");
          const name = file.split("/").slice(-1)[0];

          let fileObject = new File([""], name, { type: "text/plain" });
          fileObject.path = localPath;

          return fileObject;
        });

        setFileStructure((currentStructure) => {
          let updatedStructure =
            currentStructure.length > 0 ? [...currentStructure] : [];

          fileObjects.forEach((file) => {
            const fullPath = file.webkitRelativePath || file.path || file.name;
            const pathParts = fullPath.split("/");

            let currentLevel = updatedStructure;

            pathParts.forEach((part, index) => {
              let node = currentLevel.find((f) => f.name === part);

              if (!node) {
                const isFolder = index < pathParts.length - 1;
                node = {
                  name: part,
                  path: pathParts.slice(0, index + 1).join("/"),
                  isFolder: isFolder,
                  children: isFolder ? [] : null,
                  file: isFolder ? undefined : file,
                  isOpen: false,
                  isLocal: false,
                };
                currentLevel.push(node);
              }

              if (node.isFolder) {
                currentLevel = node.children;
              }
            });
          });

          return updatedStructure;
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    if (projectName !== "") {
      fetchFiles();
    }
  }, [projectName, setFileStructure, getCookie]);

  // If job status is STARTED, then fetch the files every 5 seconds and add them to the file structure
  useEffect(() => {
    if (jobStatus === "STARTED") {
      const interval = setInterval(async () => {
        try {
          const response = await fetch(
            `https://backend.chipworks.app/customerapi/projects/files/${projectName}/`,
            {
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
              },
              credentials: "include",
            }
          );
          const data = await response.json();
          const files = data.files;
          console.log("Files:", files);

          const fileObjects = files.map((file) => {
            const localPath = file.split("/").slice(4).join("/");
            const name = file.split("/").slice(-1)[0];

            let fileObject = new File([""], name, { type: "text/plain" });
            fileObject.path = localPath;

            return fileObject;
          });

          setFileStructure((currentStructure) => {
            let updatedStructure =
              currentStructure.length > 0 ? [...currentStructure] : [];

            fileObjects.forEach((file) => {
              const fullPath =
                file.webkitRelativePath || file.path || file.name;
              const pathParts = fullPath.split("/");

              let currentLevel = updatedStructure;

              pathParts.forEach((part, index) => {
                let node = currentLevel.find((f) => f.name === part);

                if (!node) {
                  const isFolder = index < pathParts.length - 1;
                  node = {
                    name: part,
                    path: pathParts.slice(0, index + 1).join("/"),
                    isFolder: isFolder,
                    children: isFolder ? [] : null,
                    file: isFolder ? undefined : file,
                    isOpen: false,
                    isLocal: false,
                  };
                  currentLevel.push(node);
                }

                if (node.isFolder) {
                  currentLevel = node.children;
                }
              });
            });

            return updatedStructure;
          });
        } catch (error) {
          console.error("Error fetching files:", error);
        }
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [jobStatus, projectName, setFileStructure, getCookie]);

  useEffect(() => {
    // Effect for global click handler
    const handleClick = (event) => {
      if (contextMenu.show) {
        setContextMenu((prev) => ({ ...prev, show: false }));
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [contextMenu.show]);

  const handleCreateItem = (name, type) => {
    if (addingNode) {
      makeNewItem(name, type, addingNode);
      setAddingNode(null);
    } else {
      makeNewItem(name, type);
    }
  };

  const makeNewItem = (name, type, parentNode: FileNode | null = null) => {
    if (type === "File") {
      // Create a new empty file. The content is an empty Blob.
      console.log("Creating file:", name);
      const newFile = new File([""], name, { type: "text/plain" });
      if (parentNode) {
        uploadFile(newFile, name, parentNode.path);
        parentNode.children.push({
          name: name,
          path: parentNode.path + "/" + name,
          isFolder: false,
          children: [],
          file: newFile,
          isOpen: false,
          isLocal: true,
        });
      } else {
        uploadFile(newFile, name, "");
        setFileStructure((currentStructure) => {
          let updatedStructure =
            currentStructure.length > 0 ? [...currentStructure] : [];
          updatedStructure.push({
            name: name,
            path: name,
            isFolder: false,
            children: [],
            file: newFile,
            isOpen: false,
            isLocal: true,
          });
          return updatedStructure;
        });
      }
    } else if (type === "Folder") {
      if (parentNode) {
        parentNode.children.push({
          name: name,
          path: parentNode.path + "/" + name,
          isFolder: true,
          children: [],
          isOpen: false,
          isLocal: true,
        });
      } else {
        setFileStructure((currentStructure) => {
          let updatedStructure =
            currentStructure.length > 0 ? [...currentStructure] : [];
          updatedStructure.push({
            name: name,
            path: name,
            isFolder: true,
            children: [],
            isOpen: false,
            isLocal: true,
          });
          return updatedStructure;
        });
      }
    }
  };

  const handleDragStart = (e, item) => {
    setDraggedItem(item);
  };

  const handleDrop = async (e, targetFolder) => {
    e.stopPropagation();
    if (!draggedItem) return;

    // Check if the target folder already has an item with the same name
    if (targetFolder === null) {
      const duplicate = fileStructure.find(
        (node) => node.name === draggedItem.name
      );

      if (duplicate) {
        alert("A file or folder with the same name already exists.");
        return;
      }

      // Add the dragged item as a child of the root
      let updatedStructure = [...fileStructure, draggedItem];

      // Remove the dragged item from its previous parent
      const draggedItemParentPath = draggedItem.path
        .split("/")
        .slice(0, -1)
        .join("/");

      const cleanChildren = (nodes: FileNode[]): FileNode[] => {
        return nodes.map((node) => {
          if (node.path === draggedItemParentPath) {
            // Remove the dragged item from its previous parent
            return {
              ...node,
              children: node.children.filter(
                (child) => child.name !== draggedItem.name
              ),
            };
          } else if (node.isFolder) {
            return {
              ...node,
              children: cleanChildren(node.children),
            };
          }
          return node;
        });
      };

      updatedStructure = cleanChildren(updatedStructure);

      // Recursively update the children of the dragged item (if it's a folder)
      const updateChildren = (nodes, parentPath) => {
        return nodes.map((node) => {
          const newPath = `${parentPath}/${node.name}`;
          if (node.isFolder) {
            return {
              ...node,
              path: newPath,
              children: updateChildren(node.children, newPath),
            };
          } else {
            if (node.path !== newPath) {
              const origPath = node.path.split("/").slice(0, -1).join("/");
              const newPathNoName = newPath.split("/").slice(0, -1).join("/");
              moveFile(
                node.name,
                origPath === "" ? "/" : origPath,
                node.name,
                newPathNoName === "" ? "/" : newPathNoName
              );
            }
            return {
              ...node,
              path: newPath,
            };
          }
        });
      };

      updatedStructure = updateChildren(updatedStructure, "");
      setFileStructure(updatedStructure);
    } else {
      const duplicate = targetFolder.children.find(
        (child) => child.name === draggedItem.name
      );

      if (duplicate) {
        alert(
          "A file or folder with the same name already exists in this folder."
        );
        return; // Stop the drop operation
      }

      // Logic to update the file structure
      const addChild = (nodes: FileNode[]): FileNode[] => {
        return nodes.map((node) => {
          if (node.path === targetFolder.path) {
            // Add the dragged item as a child of the target folder
            return {
              ...node,
              children: [...node.children, draggedItem],
            };
          } else if (node.isFolder) {
            return {
              ...node,
              children: addChild(node.children),
            };
          }
          return node;
        });
      };

      let updatedStructure = addChild(fileStructure);

      const draggedItemParentPath = draggedItem.path
        .split("/")
        .slice(0, -1)
        .join("/");
      const cleanChildren = (nodes: FileNode[]): FileNode[] => {
        return nodes.map((node) => {
          if (node.path === draggedItemParentPath) {
            // Remove the dragged item from its previous parent
            return {
              ...node,
              children: node.children.filter(
                (child) => child.name !== draggedItem.name
              ),
            };
          } else if (node.isFolder) {
            return {
              ...node,
              children: cleanChildren(node.children),
            };
          }
          return node;
        });
      };

      updatedStructure = cleanChildren(updatedStructure);

      // If dragged item is in the root, remove it from the root
      const draggedItemPathSplit = draggedItem.path.split("/");
      if (
        draggedItemPathSplit.length === 1 ||
        (draggedItemPathSplit.length === 2 && draggedItemPathSplit[1] === "") ||
        (draggedItemPathSplit.length === 2 && draggedItemPathSplit[0] === "") ||
        (draggedItemPathSplit.length === 3 &&
          draggedItemPathSplit[0] === "" &&
          draggedItemPathSplit[2] === "")
      ) {
        updatedStructure = updatedStructure.filter(
          (node) => node.name !== draggedItem.name
        );
      }

      // Recursively update the children of the dragged item (if it's a folder)
      const updateChildren = (nodes, parentPath) => {
        return nodes.map((node) => {
          const newPath = `${parentPath}/${node.name}`;
          if (node.isFolder) {
            return {
              ...node,
              path: newPath,
              children: updateChildren(node.children, newPath),
            };
          } else {
            if (node.path !== newPath) {
              const origPath = node.path.split("/").slice(0, -1).join("/");
              const newPathNoName = newPath.split("/").slice(0, -1).join("/");
              moveFile(
                node.name,
                origPath === "" ? "/" : origPath,
                node.name,
                newPathNoName === "" ? "/" : newPathNoName
              );
            }
            return {
              ...node,
              path: newPath,
            };
          }
        });
      };

      updatedStructure = updateChildren(updatedStructure, "");
      setFileStructure(updatedStructure);
    }

    // Reset dragged item
    setDraggedItem(null);
  };

  const moveFile = async (
    file_name: string,
    original_path: string,
    new_name: string,
    new_path: string
  ) => {
    try {
      const response = await fetch(
        `https://backend.chipworks.app/customerapi/projects/files/move/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify({
            project: projectName,
            file_path: original_path,
            file_name: file_name,
            dest_file_path: new_path,
            dest_file_name: new_name,
          }),
        }
      );
      if (!response.ok) {
        const text = await response.text();
        console.error("Error moving file:", text);
        return;
      }
      console.log("File moved successfully");
    } catch (error) {
      console.error("Error moving file:", error);
      return;
    }
  };

  const onClose = () => {
    setShowUploadPopup(false);
  };

  const makeNode = (node: FileNode, type: string) => {
    setShowNameInput(true);
    setType(type);
    setAddingNode(node);
  };

  const uploadFile = async (
    file: File,
    file_name: string,
    file_path: string
  ) => {
    console.log("Uploading file:", file_name, file_path);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file_name);
    if (file_path !== "") {
      formData.append("file_path", "/" + file_path);
    } else {
      formData.append("file_path", "/");
    }
    formData.append("project", projectName);

    try {
      const response = await fetch(
        "https://backend.chipworks.app/customerapi/projects/upload/",
        {
          method: "POST",
          headers: {
            "X-CSRFToken": getCookie("csrftoken"),
          },
          credentials: "include",
          body: formData,
        }
      );

      if (!response.ok) {
        const text = await response.text();
        console.error("Error uploading file:", text);
        return;
      }

      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const onUploadComplete = (files) => {
    setFileStructure(addFilesToStructure(files));
    setShowUploadPopup(false);
  };

  const onUpload = () => {
    setShowUploadPopup(true);
  };

  const downloadProjectFiles = async () => {
    try {
      const response = await fetch(
        `https://backend.chipworks.app/customerapi/projects/download/${projectName}/`,
        {
          method: "GET",
          headers: {
            "X-CSRFToken": getCookie("csrftoken"),
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        console.error("Error downloading the file");
        const text = await response.text();
        console.error(text);
        return;
      }

      const blob = await response.blob();

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `project_${projectName}.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const readFileFromServer = async (node: FileNode) => {
    try {
      let path = node.path.split("/").slice(0, -1).join("/");
      path = path + "/";

      const response = await fetch(
        `https://backend.chipworks.app/customerapi/projects/files/view/${projectName}/${node.name}/${path}/`,
        {
          credentials: "include",
        }
      );

      if (!response.ok) {
        const text = await response.text();
        console.error("Error fetching file:", text);
        return;
      }

      const data = await response.json();
      const content = data.file_content;
      console.log("File:", content);

      const fileObject = new File([content], node.name, {
        type: "text/plain",
      });
      node.file = fileObject;
      node.isLocal = true;
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const handleFileClick = async (node: FileNode) => {
    if (node.file) {
      if (!node.isLocal) {
        await readFileFromServer(node);
      }
      if (!node.content) {
        console.log("Reading file:", node.file);
        try {
          const content = await readFileContent(node.file);
          node.content = content;
        } catch (error) {
          console.error("Error reading file:", error);
        }
      }

      // Recursively set isActive to false for all nodes
      const setInactive = (nodes: FileNode[]): FileNode[] => {
        return nodes.map((n) => {
          if (n.isFolder) {
            return { ...n, isActive: false, children: setInactive(n.children) };
          } else {
            if (n.path === node.path) {
              return { ...n, isActive: true };
            } else {
              return { ...n, isActive: false };
            }
          }
        });
      };
      const updatedNodes = setInactive(fileStructure);
      setFileStructure(updatedNodes);
      console.log("Updated nodes:", updatedNodes);

      onFileSelect(node);
    }
  };

  const readFileContent = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.error);
      reader.readAsText(file);
    });
  };

  const addFilesToStructure = (files: File[], local = true): FileNode[] => {
    let updatedStructure = fileStructure.length > 0 ? [...fileStructure] : [];

    files.forEach((file) => {
      // Handling both file and folder uploads
      const fullPath = file.webkitRelativePath || file.path || file.name; // Use file.name for single files
      const pathParts = fullPath.split("/");

      let currentLevel = updatedStructure;

      pathParts.forEach((part, index) => {
        let node = currentLevel.find((f) => f.name === part);

        if (!node) {
          const isFolder = index < pathParts.length - 1;
          node = {
            name: part,
            path: pathParts.slice(0, index + 1).join("/"),
            isFolder: isFolder,
            children: isFolder ? [] : null,
            file: isFolder ? undefined : file,
            isOpen: false,
            isLocal: local,
          };
          currentLevel.push(node);
        }

        if (node.isFolder) {
          currentLevel = node.children;
        }
      });
    });

    return updatedStructure;
  };

  const toggleFolder = (folderNode: FileNode) => {
    const toggleFolderHelper = (nodes: FileNode[]): FileNode[] => {
      return nodes.map((node) => {
        if (node.path === folderNode.path) {
          return { ...node, isOpen: !node.isOpen };
        } else if (node.isFolder) {
          return { ...node, children: toggleFolderHelper(node.children) };
        }
        return node;
      });
    };

    const newFileStructure = toggleFolderHelper(fileStructure);
    setFileStructure(newFileStructure);
  };

  const renderFileStructure = (nodes: FileNode[], depth: number = 0) => {
    return (
      <div
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => handleDrop(e, null)}
        style={{ height: "100%" }}
      >
        {nodes.map((node) => (
          <div
            className="file-tree-node"
            key={node.path}
            style={{
              marginLeft: `${depth + 5}px`,
              borderLeft: "0.5px solid #83BCA9",
              paddingLeft: "5px",
            }}
          >
            <div className="file-tree-node-content">
              {node.isFolder ? (
                <p
                  draggable
                  onDragStart={(e) => handleDragStart(e, node)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => handleDrop(e, node)}
                  className="folder-name"
                  onClick={() => toggleFolder(node)}
                  onContextMenu={(e) => {
                    e.stopPropagation();
                    handleContextMenu(e, node);
                  }}
                >
                  <FontAwesomeIcon
                    className="folder-icon"
                    icon={node.isOpen ? faFolderOpen : faFolder}
                    style={{ marginRight: "5px" }}
                  />
                  {node.name}
                </p>
              ) : (
                <p
                  draggable
                  onDragStart={(e) => handleDragStart(e, node)}
                  className={`file-name ${node.isActive ? "active-file" : ""}`}
                  onClick={() => node.file && handleFileClick(node)}
                  onContextMenu={(e) => {
                    e.stopPropagation();
                    handleContextMenu(e, node);
                  }}
                >
                  {node.name}
                </p>
              )}

              {node.isFolder &&
                node.isOpen &&
                renderFileStructure(node.children, depth + 1)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const deleteNode = (node: FileNode) => {
    console.log("Deleting node:", node);
    // Send a request to delete every node on the path of the node
    const deleteNodeHelper = (nodes: FileNode[]) => {
      nodes.forEach((n) => {
        if (n.path.startsWith(node.path)) {
          if (!n.isFolder) {
            const pathNoName = n.path.split("/").slice(0, -1).join("/");
            deleteFile(n.name, pathNoName === "" ? "/" : pathNoName);
          } else {
            deleteFolder(n.path);
          }
        } else if (n.isFolder) {
          deleteNodeHelper(n.children);
        }
      });
    };

    deleteNodeHelper(fileStructure);

    // Filter deleted files from the root
    let newFileStructure = fileStructure.filter(
      (n) => !n.path.startsWith(node.path)
    );

    // Recursively remove deleted files from the file structure
    const removeDeletedFiles = (nodes: FileNode[]): FileNode[] => {
      return nodes.map((n) => {
        if (n.isFolder) {
          const newChildren = n.children.filter(
            (child) => !child.path.startsWith(node.path)
          );
          return { ...n, children: removeDeletedFiles(newChildren) };
        } else {
          return n;
        }
      });
    };

    newFileStructure = removeDeletedFiles(newFileStructure);
    setFileStructure(newFileStructure);
  };

  const deleteFolder = async (folder_path: string) => {
    console.log("Deleting folder:", folder_path);
    try {
      const response = await fetch(
        `https://backend.chipworks.app/customerapi/projects/files/delete-folder/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify({
            project: projectName,
            folder_path: folder_path,
          }),
        }
      );
      if (!response.ok) {
        const text = await response.text();
        console.error("Error deleting folder:", text);
        return;
      }
      console.log("Folder deleted successfully");
    } catch (error) {
      console.error("Error deleting folder:", error);
      return;
    }
  };

  const deleteFile = async (file_name: string, file_path: string) => {
    console.log("Deleting file:", file_name, file_path);
    try {
      const response = await fetch(
        `https://backend.chipworks.app/customerapi/projects/files/delete/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify({
            project: projectName,
            file_path: file_path,
            file_name: file_name,
          }),
        }
      );
      if (!response.ok) {
        const text = await response.text();
        console.error("Error deleting file:", text);
        return;
      }
      console.log("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file:", error);
      return;
    }
  };

  const renameNode = (node: FileNode) => {
    setType(node.isFolder ? "Folder" : "File");
    setRenamingNode(node);
    console.log("Renaming node:", node);
    setOriginalName(node.name);
    setShowRenameInput(true);
  };

  const handleRenameItem = (name) => {
    if (!renamingNode) return;

    const renameNodeHelper = (nodes: FileNode[]): FileNode[] => {
      return nodes.map((node) => {
        const pathSplit = node.path.split("/").slice(0, -1);
        const newPathSplit = [...pathSplit, name];
        const newPath = newPathSplit.join("/");
        if (node.path === renamingNode.path) {
          moveFile(node.name, pathSplit.join("/"), name, pathSplit.join("/"));
          return { ...node, name: name, path: newPath };
        } else if (node.isFolder) {
          return { ...node, children: renameNodeHelper(node.children) };
        }
        return node;
      });
    };
    let newFileStructure = renameNodeHelper(fileStructure);

    // Update the path of the renamed node's children
    const updateChildren = (nodes, parentPath) => {
      return nodes.map((node) => {
        const newPath = `${parentPath}/${node.name}`;
        if (node.isFolder) {
          return {
            ...node,
            path: newPath,
            children: updateChildren(node.children, newPath),
          };
        } else {
          return {
            ...node,
            path: newPath,
          };
        }
      });
    };

    newFileStructure = updateChildren(newFileStructure, "");
    setFileStructure(newFileStructure);

    resetRenamingNode();
  };

  const resetRenamingNode = () => {
    setRenamingNode(null);
    setOriginalName("");
    setShowRenameInput(false);
  };

  const handleContextMenu = (event: React.MouseEvent, node: FileNode) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      node: node,
      show: true,
    });
  };

  const renderContextMenu = () => {
    if (!contextMenu.show) return null;

    const style = {
      top: contextMenu.y,
      left: contextMenu.x,
      position: "absolute" as "absolute",
      zIndex: 1000,
      backgroundColor: "#00C2D1",
      color: "black",
    };

    return (
      <div style={style}>
        <div
          className="context-menu-item"
          onClick={() => contextMenu.node && deleteNode(contextMenu.node)}
          style={{ cursor: "pointer" }}
        >
          Delete
        </div>
        <div
          className="context-menu-item"
          onClick={() => contextMenu.node && renameNode(contextMenu.node)}
          style={{ cursor: "pointer" }}
        >
          Rename
        </div>
        {contextMenu.node && contextMenu.node.isFolder && (
          <div>
            <div
              className="context-menu-item"
              onClick={() =>
                contextMenu.node && makeNode(contextMenu.node, "File")
              }
              style={{ cursor: "pointer" }}
            >
              New File
            </div>
            <div
              className="context-menu-item"
              onClick={() =>
                contextMenu.node && makeNode(contextMenu.node, "Folder")
              }
              style={{ cursor: "pointer" }}
            >
              New Folder
            </div>{" "}
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div
        className="file-explorer"
        style={{ paddingTop: 0, position: "relative" }}
      >
        <RelativeLoading />
      </div>
    );
  }

  return (
    <div
      className="file-explorer"
      style={{ paddingTop: 0, position: "relative" }}
    >
      <FileExplorerToolbar
        onUpload={onUpload}
        onCreateFile={() => {
          setShowNameInput(true);
          setType("File");
        }}
        onCreateFolder={() => {
          setShowNameInput(true);
          setType("Folder");
        }}
        onDownload={downloadProjectFiles}
      />
      {showNameInput && (
        <NameInputPopup
          type={type}
          onClose={() => {
            setShowNameInput(false);
            setAddingNode(null);
          }}
          onSubmit={handleCreateItem}
        />
      )}
      {showRenameInput && (
        <RenameInputPopup
          type={type}
          name={originalName}
          onClose={() => resetRenamingNode()}
          onSubmit={handleRenameItem}
        />
      )}
      <div style={{ height: "100%", paddingTop: "10px" }}>
        {renderFileStructure(fileStructure)}
      </div>
      {showUploadPopup && (
        <FileUploadPopup
          onClose={onClose}
          onUploadComplete={onUploadComplete}
          projectName={projectName}
        />
      )}
      {renderContextMenu()}
    </div>
  );
};

export default FileExplorer;
