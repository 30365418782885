import React, { useState } from "react";
import { useAuth } from "./authentication.tsx";
import Footer from "./components/NavBar/Footer.tsx";
import "./index.css";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { GoogleLogin } from "@react-oauth/google";

const LoginPage: React.FC = () => {
  const { setIsAuthenticated } = useAuth();
  const [isSigningUp, setIsSigningUp] = useState(false);

  // Sign-up form state
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signupErrors, setSignupErrors] = useState({});

  // Password reset
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetErrors, setResetErrors] = useState({});

  function setCookie(name, value, daysToExpire) {
    var date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    var expires = "expires=" + date.toUTCString();
    document.cookie =
      name +
      "=" +
      encodeURIComponent(value) +
      ";" +
      expires +
      ";path=/;Secure;SameSite=Strict";
  }

  const handleLoginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSignupErrors({}); // Clear any previous errors

    ReactGA.event("login");

    try {
      const response = await fetch(
        "https://backend.chipworks.app/customerapi/auth/login/",
        {
          method: "POST",
          body: JSON.stringify({ username, password }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        const data = await response.json();
        setSignupErrors(data);
        console.log("Login failed:", data);
      } else {
        const data = await response.json();
        setCookie("chipworks_username", data.username, 14);
        console.log("Login successful:", data);
        setIsAuthenticated(true);
        // navigate("/projects");
      }
    } catch (error) {
      console.error("Login failed:", error);
      alert("Login failed, try again.");
    }
  };

  const handleSignupSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setSignupErrors({}); // Clear any previous errors

    ReactGA.event("sign_up");

    if (password !== confirmPassword) {
      setSignupErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: ["Passwords do not match!"],
      }));
      return;
    }

    const recaptchaToken = await window.grecaptcha.enterprise?.execute(
      "6LfOSTspAAAAANg3NoRvLKCENLYegKj9ubX522x3",
      {
        action: "SIGNUP",
      }
    );

    if (!recaptchaToken) {
      alert("Please verify you are not a robot.");
      return;
    }

    let password2 = confirmPassword;
    try {
      const response = await fetch(
        "https://backend.chipworks.app/customerapi/auth/register/",
        {
          method: "POST",
          body: JSON.stringify({
            first_name,
            last_name,
            email,
            username,
            password,
            password2,
            recaptchaToken,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (!response.ok) {
        const data = await response.json();
        setSignupErrors(data);
        console.log(data);
      } else {
        setIsSigningUp(false); // Switch back to login form
      }
    } catch (error) {
      alert("Error signing up. Try again.");
      console.error("Signup failed:", error);
    }
  };

  const handleGoogleSignIn = async (id_token: string) => {
    ReactGA.event({ category: "User", action: "signin_with_google" });

    fetch("https://backend.chipworks.app/customerapi/auth/google/", {
      method: "POST",
      body: JSON.stringify({ token: id_token }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          const data = response.json();
          data.then((data) => {
            setCookie("chipworks_username", data.username, 14);
            setIsAuthenticated(true);
          });
        } else {
          alert("Google sign-in failed, try again.");
          const text = response.text();
          text.then((text) => {
            console.log(text);
          });
        }
      })
      .catch(() => {
        alert("Google sign-in failed, try again.");
      });
  };

  const handlePasswordResetSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setResetErrors({}); // Clear any previous errors

    try {
      const recaptchaToken = await window.grecaptcha.enterprise?.execute(
        "6LfOSTspAAAAANg3NoRvLKCENLYegKj9ubX522x3",
        {
          action: "passoword_reset",
        }
      );

      if (!recaptchaToken) {
        alert("Please verify you are not a robot.");
        return;
      }

      const response = await fetch(
        "https://backend.chipworks.app/customerapi/auth/password-reset/",
        {
          method: "POST",
          body: JSON.stringify({ email: resetEmail, recaptchaToken }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        const text = await response.text();
        console.log(text);
      } else {
        alert("Password reset email sent. Please check your email.");
        setShowPasswordReset(false);
      }
    } catch (error) {
      alert("Password reset failed, try again.");
    }
  };

  return (
    <div
      className="login-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {isSigningUp ? (
        <form
          onSubmit={handleSignupSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/logo192.png"
            alt="logo"
            style={{ height: "100px", width: "100px", margin: "auto" }}
          />
          <h2 style={{ textAlign: "center" }}>
            Register Your ChipWorks Account
          </h2>
          {/* Sign-up form fields */}
          <div className="signup-form">
            <div className="form-group">
              <label htmlFor="first-name">First Name:</label>
              <input
                id="first-name"
                type="text"
                placeholder="First Name"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">Last Name:</label>
              <input
                id="last-name"
                type="text"
                placeholder="Last Name"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input
                id="username"
                autoComplete="username"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                id="password"
                autoComplete="new-password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password:</label>
              <input
                id="confirm-password"
                autoComplete="new-password"
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
          </div>
          {Object.entries(signupErrors).map(([field, errors]) => (
            <div key={field}>
              {errors.map((error, index) => (
                <div key={index} style={{ color: "red" }}>
                  {field}: {error}
                </div>
              ))}
            </div>
          ))}
          <p style={{ fontSize: "8pt" }}>
            This site is protected by reCAPTCHA and the Google{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://policies.google.com/privacy"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://policies.google.com/terms"
            >
              Terms of Service
            </a>{" "}
            apply.
          </p>
          <p style={{ fontSize: "8pt" }}>
            By registering, you agree to the ChipWorks{" "}
            {/* Link in a new tab to the \terms page */}
            <Link
              style={{ textDecoration: "none" }}
              to="/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service and Privacy Policy
            </Link>
            .
          </p>
          <button
            type="submit"
            className="g-recaptcha"
            data-sitekey="6LcUtjopAAAAAN73psG6v_fT2w1nGxZKuZx-lChf"
          >
            Sign Up
          </button>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              console.log(credentialResponse);
              if (credentialResponse.credential)
                handleGoogleSignIn(credentialResponse.credential);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
          <button
            type="button"
            onClick={() => setIsSigningUp(false)}
            style={{ marginTop: "10px" }}
          >
            Back to Login
          </button>
        </form>
      ) : showPasswordReset ? (
        <form
          onSubmit={handlePasswordResetSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Reset Password</h2>
          <input
            type="email"
            placeholder="Enter your email"
            onChange={(e) => setResetEmail(e.target.value)}
            required
          />
          {Object.entries(resetErrors).map(([field, errors]) => (
            <div key={field}>
              {errors.map((error, index) => (
                <div key={index} style={{ color: "red" }}>
                  {error}
                </div>
              ))}
            </div>
          ))}
          <p style={{ fontSize: "8pt" }}>
            This site is protected by reCAPTCHA and the Google{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://policies.google.com/privacy"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://policies.google.com/terms"
            >
              Terms of Service
            </a>{" "}
            apply.
          </p>
          <button type="submit">Send Reset Email</button>
          <button type="button" onClick={() => setShowPasswordReset(false)}>
            Back to Login
          </button>
        </form>
      ) : (
        <form
          onSubmit={handleLoginSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/logo192.png"
            alt="logo"
            style={{ height: "100px", width: "100px", margin: "auto" }}
          />
          <h2 style={{ textAlign: "center" }}>ChipWorks Login</h2>
          {/* Login form fields */}
          <input
            autoComplete="username"
            type="text"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            autoComplete="current-password"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {Object.entries(signupErrors).map(([field, errors]) => (
            <div key={field}>
              {errors.map((error, index) => (
                <div key={index} style={{ color: "red" }}>
                  {error}
                </div>
              ))}
            </div>
          ))}
          <p style={{ fontSize: "8pt" }}>
            This site is protected by reCAPTCHA and the Google{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://policies.google.com/privacy"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://policies.google.com/terms"
            >
              Terms of Service
            </a>{" "}
            apply.
          </p>
          <button type="submit">Login</button>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              console.log(credentialResponse);
              if (credentialResponse.credential)
                handleGoogleSignIn(credentialResponse.credential);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
          <button
            type="button"
            onClick={() => setIsSigningUp(true)}
            style={{ marginTop: "10px" }}
          >
            Don't have an account? Sign Up
          </button>
          <button type="button" onClick={() => setShowPasswordReset(true)}>
            Forgot Password?
          </button>
        </form>
      )}
      <Footer />
    </div>
  );
};

export default LoginPage;
