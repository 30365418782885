import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./authentication.tsx";
import "./index.css";
import NavBar from "./components/NavBar/NavBar.tsx";
import Footer from "./components/NavBar/Footer.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faPlus,
  faTrash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

const ProjectsPage: React.FC = () => {
  const { getCookie } = useAuth();
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          "https://backend.chipworks.app/customerapi/projects/",
          {
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );
        const data = await response.json();
        console.log("Projects data:", data);
        setProjects(data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, []);

  const handleAddNewProject = () => {
    navigate("/editor?newProject=true");
  };

  const handleEditProject = (project) => {
    navigate(`/editor?project=${project.project_name}`);
  };

  // Function to handle project deletion
  const handleDeleteProject = async (e, projectName) => {
    e.stopPropagation();
    if (window.confirm(`Are you sure you want to delete ${projectName}?`)) {
      try {
        // API call to delete the project
        const response = await fetch(
          "https://backend.chipworks.app/customerapi/projects/delete/",
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": getCookie("csrftoken"),
            },
            credentials: "include",
            body: JSON.stringify({
              project_name: projectName,
            }),
          }
        );
        const data = await response.text();
        console.log(data);
        // Update the projects state
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.project_name !== projectName)
        );
      } catch (error) {
        console.error("Error deleting project:", error);
      }
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();

    // Format the date as YYYY-MM-DD
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  }

  return (
    <div className="projects-container">
      <NavBar />

      {/* Notice Section */}
      <div className="notice-section">
        <p style={{ paddingLeft: "10px" }}>
          Notice: Manage your subsciption on the{" "}
          <Link to="/account">Account Page</Link>.
        </p>
      </div>

      {/* Main Projects Area */}
      <div className="main-projects-area">
        {/* Add New Project Button */}
        <div className="label-new-project">
          <h1 style={{ fontWeight: "normal" }}>All Projects</h1>
          <button className="new-project-button" onClick={handleAddNewProject}>
            <FontAwesomeIcon
              className="plus-icon"
              icon={faPlus}
              style={{ marginRight: "10px" }}
            />
            New Project
          </button>
        </div>

        {/* Projects List */}
        <div className="projects-list">
          {projects.map((project) => (
            <div
              key={project.project_name}
              className="project-item"
              onClick={() => handleEditProject(project)}
            >
              <h3>{project.project_name}</h3>

              <p>
                <FontAwesomeIcon
                  className="clock-icon"
                  icon={faClock}
                  style={{ marginRight: "5px" }}
                />
                Modified {formatDate(project.date_updated)}
              </p>

              {project.job_running && project.job_running !== "NOJOB" ? (
                <button className="edit-project-button">
                  <FontAwesomeIcon icon={faSpinner} />
                </button>
              ) : (
                <div></div>
              )}
              <button
                className="delete-project-button"
                onClick={(e) => handleDeleteProject(e, project.project_name)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectsPage;
