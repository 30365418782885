import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App.tsx";
import { AuthProvider } from "./authentication.tsx";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <HashRouter>
        <GoogleOAuthProvider clientId="20257224131-0e637cccav3sqim3anf6gq0d4lgsj54h.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </HashRouter>
    </AuthProvider>
  </React.StrictMode>
);
