import React, { useState } from "react";
import "../../index.css";

const NameInputPopup = ({ onClose, onSubmit, type }) => {
  const [name, setName] = useState("");
  const pattern = /^[a-zA-Z0-9_-]+\.?[a-zA-Z0-9]*$/;

  const handleSubmit = () => {
    onSubmit(name, type);
    onClose();
  };

  const isInputValud = pattern.test(name);

  return (
    <div className="name-input-popup">
      <div className="name-input-content">
        <h3 style={{ color: "black" }}>Create New {type}</h3>
        {/* Only allow alphanumeric characters and at most one period */}
        <input
          type="text"
          pattern="^[a-zA-Z0-9_-]*\.?[a-zA-Z0-9]*$"
          title="Only alphanumeric characters and at most one period"
          maxLength={50}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={`Enter ${type} Name`}
          style={{}}
        />
        <div className="name-input-buttons" style={{ margin: "10px" }}>
          <button onClick={handleSubmit} disabled={!isInputValud}>
            Create
          </button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default NameInputPopup;
