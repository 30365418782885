import React from "react";
import DropdownMenu from "./DropdownMenu.tsx";
import "../../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faBarsProgress,
  faCheck,
  faXmark,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

const NavBar: React.FC<{
  projectName: string;
  jobStatus: string;
  flowLoading: boolean;
  runFlow: () => void;
  cancelJob: () => void;
  viewOutput: () => void;
}> = ({
  projectName = "",
  jobStatus = "NOJOB",
  flowLoading = false,
  runFlow,
  cancelJob,
  viewOutput,
}) => {
  return (
    <nav className="navbar">
      {/* Navigation items here */}
      <a className="home-button" href="/" style={{ padding: 0 }}>
        <div
          className="logo"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
            height: "fit-content",
          }}
        >
          <img
            src="/logo192.png"
            alt="logo"
            style={{
              height: "20px",
              width: "20px",
              margin: "auto",
              padding: 0,
              borderRadius: 0,
            }}
          />
          <p
            style={{
              marginLeft: "5px",
              margin: "auto",
            }}
          >
            ChipWorks
          </p>
        </div>
      </a>
      {projectName !== "" && (
        <p
          className="project-name"
          style={{ padding: 0, margin: 0, textAlign: "center" }}
        >
          Project: {projectName}
        </p>
      )}
      <div
        className="right-side-nav-items"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {jobStatus === "QUEUED" ? (
          <div className="flow-status-bar">
            <FontAwesomeIcon icon={faClock} style={{ color: "orange" }} />
            <p>Queued</p>
            <button
              className="run-flow-button"
              onClick={cancelJob}
              style={{
                backgroundColor: "#CF5C36",
                padding: "5px 10px",
                color: "#ddd",
              }}
            >
              Cancel Job
            </button>
          </div>
        ) : jobStatus === "STARTED" ? (
          <div className="flow-status-bar">
            <FontAwesomeIcon icon={faBarsProgress} style={{ color: "black" }} />
            <p>Running</p>
            <button
              className="run-flow-button"
              onClick={viewOutput}
              style={{
                backgroundColor: "#CF5C36",
                padding: "5px 10px",
                color: "#ddd",
              }}
            >
              View Output
            </button>
          </div>
        ) : jobStatus === "SUCCESS" ? (
          <div className="flow-status-bar">
            <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
            <p>Success</p>
            <button
              className="run-flow-button"
              onClick={runFlow}
              style={{
                backgroundColor: "#CF5C36",
                padding: "5px 10px",
                color: "#ddd",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
              }}
              disabled={flowLoading}
            >
              {flowLoading && (
                <FontAwesomeIcon
                  className="fa-spin"
                  icon={faGear}
                  style={{
                    marginRight: "5px",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                />
              )}
              Run Flow
            </button>
          </div>
        ) : jobStatus === "FAILURE" ||
          jobStatus === "KILLED" ||
          jobStatus === "REVOKED" ? (
          <div className="flow-status-bar">
            <FontAwesomeIcon icon={faXmark} style={{ color: "red" }} />
            <p>Failure</p>
            <button
              className="run-flow-button"
              onClick={runFlow}
              style={{
                backgroundColor: "#CF5C36",
                padding: "5px 10px",
                color: "#ddd",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
              }}
              disabled={flowLoading}
            >
              {flowLoading && (
                <FontAwesomeIcon
                  className="fa-spin"
                  icon={faGear}
                  style={{
                    marginRight: "5px",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                />
              )}
              Run Flow
            </button>
          </div>
        ) : projectName !== "" ? (
          <button
            className="run-flow-button"
            onClick={runFlow}
            style={{
              backgroundColor: "#CF5C36",
              padding: "5px 10px",
              color: "#ddd",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
            }}
            disabled={flowLoading}
          >
            {flowLoading && (
              <FontAwesomeIcon
                className="fa-spin"
                icon={faGear}
                style={{
                  marginRight: "5px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
            )}
            Run Flow
          </button>
        ) : (
          <></>
        )}
        <DropdownMenu />
      </div>
    </nav>
  );
};

export default NavBar;
