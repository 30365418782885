import React from "react";

const StatusBar = ({ percentage }) => {
  const barFillStyle = {
    width: `${percentage}%`,
    backgroundColor: "limegreen",
    height: "100%",
    borderRadius: "5px",
  };

  const barTextStyle = {
    position: "absolute", // Position text absolutely within the parent
    width: "100%", // Take the full width of the parent
    textAlign: "center", // Center the text
    lineHeight: "20px", // Center the text vertically
  };

  return (
    <div
      style={{
        position: "relative",
        border: "1px solid limegreen",
        width: "100%",
        height: "20px",
        borderRadius: "5px",
      }}
    >
      <div style={barFillStyle}></div>
      <div style={barTextStyle}>{percentage.toFixed(2)}% Memory</div>
    </div>
  );
};

export default StatusBar;
