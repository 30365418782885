import React, { useState, useEffect } from "react";
import StripePayment from "./components/Stripe/StripePayments.tsx";
import NavBar from "./components/NavBar/NavBar.tsx";
import Footer from "./components/NavBar/Footer.tsx";
import "./index.css";

const AccountPage = () => {
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await fetch(
          "https://backend.chipworks.app/customerapi/auth/info/",
          {
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );
        const data = await response.json();
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
        setUsername(data.username);
      } catch (error) {
        console.error("Error fetching account:", error);
      }
    };
    fetchAccount();
  }, []);

  return (
    <div>
      <NavBar />
      <div className="account-container">
        <h1>Account Information</h1>
        <p>
          <b>Name:</b> {first_name} {last_name}
        </p>
        <p>
          <b>Username:</b> {username}
        </p>
        <p>
          <b>Email:</b> {email}
        </p>
        <StripePayment />
      </div>
      <Footer />
    </div>
  );
};

export default AccountPage;
