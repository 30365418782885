import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import AutoScroll from "./AutoScroll/AutoScroll.tsx";
import StatusBar from "./StatusBar/StatusBar.tsx";

interface TerminalProps {
  isVisible: boolean;
  onClose: () => void;
  onCancel: () => void;
  socketUrl: string;
  streamToken: string;
}

const Terminal: React.FC<TerminalProps> = ({
  isVisible,
  onClose,
  onCancel,
  socketUrl,
  streamToken,
}) => {
  const [messages, setMessages] = useState<string[]>([]);
  const socketRef = useRef<WebSocket | null>(null);

  const [percentage, setPercentage] = useState(0);

  const addMessage = (newMessage: string) => {
    // Delete messages in chunks of 1000 once the limit of 10000 is reached
    setMessages((prevMessages) => {
      let newMessages = [...prevMessages];
      if (newMessages.length >= 10000) {
        newMessages.splice(0, 1000); // remove the first 1000 messages
      }
      newMessages.push(newMessage);
      return newMessages;
    });
  };

  // WebSocket connection and message handling
  useEffect(() => {
    if (isVisible && socketUrl) {
      const ws = new WebSocket(socketUrl, "json.webpubsub.azure.v1");

      ws.onopen = () => {
        console.log("WebSocket Connected");
        const joinGroupMessage = {
          type: "joinGroup",
          group: streamToken,
          ackId: 4,
        };
        ws.send(JSON.stringify(joinGroupMessage));
        addMessage("Connected to server.");
      };

      ws.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data);

        console.log("WebSocket Message Received", data);

        if (data.type === "message") {
          // if the message starts with MEMORY_USAGE, extract the number of bytes and find the percentage of 16GB
          if (data.data.startsWith("MEMORY_USAGE")) {
            const memoryUsage = data.data.split(" ")[1];
            const memoryUsageInBytes = parseInt(memoryUsage);
            const percentage = (memoryUsageInBytes / 17179869184) * 100;
            setPercentage(percentage);
          } else {
            addMessage(data.data);
          }
        }
      };

      ws.onclose = () => {
        console.log("WebSocket Disconnected");
        addMessage("Disconnected from server.");
      };

      socketRef.current = ws;
    }

    return () => {
      socketRef.current?.close();
    };
  }, [isVisible, socketUrl, streamToken]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isVisible) {
    return null;
  }

  return (
    <div className="terminal-backdrop">
      <div className="terminal">
        <div
          className="terminal-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <button className="terminal-cancel-button" onClick={onCancel}>
            Cancel Job
          </button>

          <StatusBar percentage={percentage} />

          <button className="close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="terminal-scrollable-content">
          <AutoScroll
            className="terminal-content"
            scrollBehavior="auto"
            height={"55vh"}
          >
            {messages.map((message, index) => (
              <div key={index} className="terminal-line">
                {message}
              </div>
            ))}
          </AutoScroll>
        </div>
      </div>
    </div>
  );
};

export default Terminal;
