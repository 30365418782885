import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faFile,
  faFolder,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

const FileExplorerToolbar: React.FC = ({
  onUpload,
  onCreateFile,
  onCreateFolder,
  onDownload,
}) => {
  return (
    <div
      style={{
        padding: "10px",
        borderBottom: "1px solid #717EC3",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      <button onClick={onUpload} style={{ color: "#ddd" }}>
        <FontAwesomeIcon icon={faUpload} style={{ marginRight: "5px" }} />
        Upload
      </button>
      <button onClick={onCreateFile} style={{ color: "#ddd" }}>
        <FontAwesomeIcon icon={faFile} style={{ marginRight: "5px" }} />
        New File
      </button>
      <button onClick={onCreateFolder} style={{ color: "#ddd" }}>
        <FontAwesomeIcon icon={faFolder} style={{ marginRight: "5px" }} />
        New Folder
      </button>
      <button onClick={onDownload} style={{ color: "#ddd" }}>
        <FontAwesomeIcon icon={faDownload} style={{ marginRight: "5px" }} />
        Download Files
      </button>
    </div>
  );
};

export default FileExplorerToolbar;
