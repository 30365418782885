import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "./authentication.tsx";
import { FileStructureProvider } from "./components/FileExplorer/FileStructureContext.tsx";
import HomePage from "./home.tsx";
import EditorPage from "./editor.tsx";
import LoginPage from "./login.tsx";
import ProjectsPage from "./projects.tsx";
import AccountPage from "./account.tsx";
import PasswordResetPage from "./password.tsx";
import Terms from "./terms.tsx";
import AIMessagingApp from "./components/AIAssistant/AIAssistant.tsx";

import Loading from "./components/Loading/Loading.tsx";

import ReactGA from "react-ga4";
import "./hide-recaptcha.css";

function App() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true); // New state for tracking initial load
  const navigate = useNavigate();
  const location = useLocation();

  // Call the Google Analytics hook
  ReactGA.initialize("G-137LZPS8S8");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Page View",
    });
  }, [location]);

  function setCookie(name, value, daysToExpire) {
    var date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    var expires = "expires=" + date.toUTCString();
    document.cookie =
      name +
      "=" +
      encodeURIComponent(value) +
      ";" +
      expires +
      ";path=/;Secure;SameSite=Strict";
  }

  // Check session validity on initial load
  useEffect(() => {
    fetch("https://backend.chipworks.app/customerapi/auth/status/", {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setIsAuthenticated(true);
          const data = response.json();
          data.then((data) => {
            setCookie("chipworks_username", data.username, 14);
          });
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch(() => setIsAuthenticated(false))
      .finally(() => setLoading(false)); // Set loading to false after the check
  }, [setIsAuthenticated]);

  // Conditional redirection based on authentication status
  useEffect(() => {
    if (!loading) {
      if (isAuthenticated && location.pathname === "/login") {
        navigate("/projects");
      } else if (
        !isAuthenticated &&
        !(location.pathname === "/login" || location.pathname === "/terms")
      ) {
        navigate("/login");
      } else if (isAuthenticated && location.pathname === "/") {
        navigate("/projects");
      }
    }
  }, [isAuthenticated, loading, navigate, location.pathname]);

  if (loading) return <Loading />;

  return (
    <FileStructureProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ai" element={<AIMessagingApp />} />
        <Route path="/editor" element={<EditorPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route
          path="/password-reset/:email/:token"
          element={<PasswordResetPage />}
        />
        <Route path="/terms" element={<Terms />} />
        <Route path="*">"404 Not Found"</Route>
      </Routes>
    </FileStructureProvider>
  );
}

export default App;
