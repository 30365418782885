import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authentication.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";
import "../../index.css";

const DropdownMenu: React.FC = () => {
  const { setIsAuthenticated, getCookie } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const dropdownStyle = {
    position: "absolute",
    borderRadius: "0px",
    backgroundColor: "#00C2D1",
    borderBottom: "2px solid #717EC3",
    cursor: "pointer",
    zIndex: 1000,
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  const handleLogout = async () => {
    // Send logout request to backend
    const response = await fetch(
      "https://backend.chipworks.app/customerapi/auth/logout/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      console.error("Error logging out");
      const text = await response.text();
      console.error(text);
      return;
    }

    // Update authentication status
    setIsAuthenticated(false);
    deleteCookie("username");
    navigate("/login"); // Redirect to login page
  };

  return (
    <div
      ref={dropdownRef}
      style={{ padding: "0px", width: "fit-content", margin: "auto 0" }}
    >
      <button onClick={toggleDropdown} style={{ margin: "auto 0" }}>
        Welcome, {getCookie("chipworks_username")}
        <FontAwesomeIcon
          className="caret-icon"
          icon={faCircleChevronDown}
          style={{ marginLeft: "0px", marginTop: "auto", marginBottom: "auto" }}
        />
      </button>
      {isOpen && (
        <div style={dropdownStyle}>
          <div onClick={() => navigate("/account")}>My Account</div>
          <div
            onClick={() =>
              window.open(
                "https://forms.gle/aeUae5oGYEniP3ek9",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            Feedback
          </div>
          <div onClick={handleLogout}>Logout</div>
          {/* Other options */}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
