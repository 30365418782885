import React, { useEffect, useRef } from "react";
import Prism from "prismjs";
import DOMPurify from "dompurify";
import "prismjs/themes/prism-okaidia.css"; // or any other theme you prefer

const MobileCodeEditor = ({ content, language, onChange }) => {
  const codeRef = useRef(null);

  const loadPrismLanguage = async (lang) => {
    if (!Prism.languages[lang]) {
      try {
        await import(`prismjs/components/prism-${lang}`);
      } catch (e) {
        console.error(`Error loading the Prism language for: ${lang}`, e);
        // Fallback for unknown language
        await import("prismjs/components/prism-markup");
      }
    }
  };

  useEffect(() => {
    loadPrismLanguage(language).then(() => {
      Prism.highlightElement(codeRef.current);
    });
  }, [language, content]);

  const handleContentChange = (e) => {
    onChange(e.target.textContent);
  };

  // Sanitize the content
  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <div
      className="mobile-editor"
      style={{ height: "100%", width: "100%", backgroundColor: "#28282A" }}
    >
      <pre style={{ height: "100%", width: "100%" }}>
        <code
          ref={codeRef}
          className={`language-${language}`}
          contentEditable
          onInput={handleContentChange}
          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
        />
      </pre>
    </div>
  );
};

export default MobileCodeEditor;
