import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ProjectCreationPopup = ({ isOpen, onCreate }) => {
  const navigate = useNavigate();
  const [project_name, setProjectName] = useState("");
  const [project_description, setProjectDescription] = useState("");
  const pattern = /^[a-zA-Z0-9_-]+$/;

  const handleSubmit = () => {
    if (project_name === "") {
      alert("Project name cannot be empty");
      return;
    }

    console.log("handleSubmit");
    onCreate(project_name, project_description);
  };

  const handleCancel = () => {
    navigate("/projects");
  };

  const isInputValid = pattern.test(project_name);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3 style={{ color: "black", textAlign: "center" }}>
          Create New Project
        </h3>
        {/* Only allow alphanumeric characters in the project name */}
        <input
          type="text"
          pattern="[a-zA-Z0-9_-]+"
          title="Only alphanumeric characters"
          maxLength={50}
          placeholder="Project Name"
          value={project_name}
          onChange={(e) => setProjectName(e.target.value)}
          required
        />
        <textarea
          maxLength={200}
          placeholder="Project Description (optional)"
          value={project_description}
          onChange={(e) => setProjectDescription(e.target.value)}
        />
        <button onClick={handleSubmit} disabled={!isInputValid}>
          Create Project
        </button>
        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default ProjectCreationPopup;
