import React, {
  createContext,
  useState,
  useCallback,
  useRef,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import _ from "lodash";
import { diffChars } from "diff";
import { useAuth } from "../../authentication.tsx";

export interface FileNode {
  name: string;
  path: string;
  isFolder: boolean;
  children: FileNode[];
  content?: string;
  isOpen?: boolean;
  isLocal?: boolean;
  isActive?: boolean;
  dirtyContent?: string;
}

interface FileStructureContextType {
  fileStructure: FileNode[];
  setFileStructure: Dispatch<SetStateAction<FileNode[]>>;
  projectName: string;
  setProjectName: Dispatch<SetStateAction<string>>;
  updateFileStructure: (path: string, newContent: string) => void;
}

const defaultFileStructureContext: FileStructureContextType = {
  fileStructure: [],
  setFileStructure: () => {},
  projectName: "",
  setProjectName: () => {},
  updateFileStructure: () => {},
};

// Create the context with the default value
export const FileStructureContext = createContext<FileStructureContextType>(
  defaultFileStructureContext
);

// Define the provider component
export const FileStructureProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { getCookie } = useAuth();
  const [fileStructure, setFileStructure] = useState<FileNode[]>([]);
  const fileStructureRef = useRef<FileNode[]>(fileStructure);
  const [projectName, setProjectName] = useState<string>("");

  // Update ref whenever fileStructure changes
  useEffect(() => {
    fileStructureRef.current = fileStructure;
  }, [fileStructure]);

  const sendUpdateToServer = _.debounce((project_name) => {
    console.log("Sending update to server");

    const processDiff = (diffs) => {
      let position = 0;
      const changes = [];

      diffs.forEach((diff) => {
        if (diff.added) {
          changes.push({ action: "add", position, content: diff.value });
          position += diff.count;
        } else if (diff.removed) {
          changes.push({ action: "delete", position, content: diff.value });
        } else {
          position += diff.count;
        }
      });

      return changes;
    };

    const sendChanges = async (project_name, file_name, file_path, changes) => {
      console.log("Sending changes for", file_name, file_path, changes);
      console.log(projectName);

      const response = await fetch(
        "https://backend.chipworks.app/customerapi/projects/files/change/",
        {
          method: "POST",
          body: JSON.stringify({
            project: project_name,
            file_name,
            file_path,
            patches: changes,
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        alert(
          "Failed to send changes. You may have exceeded your maximum file storage of 1GB."
        );
        const text = await response.text();
        console.log("Error response", text);
      } else {
        console.log("Changes sent successfully");
      }
    };

    // Recursively traverse the file structure and send updates to the server
    const findChanges = (nodes, project_name) => {
      nodes.forEach((node) => {
        console.log("Checking node", node.name);
        if (!node.isFolder && node.dirtyContent) {
          // Send update to server
          console.log("Sending update for", node.name);
          console.log("Old content", node.content);
          console.log("New content", node.dirtyContent);
          const d = diffChars(node.content, node.dirtyContent);
          console.log("Diff", d);
          const changes = processDiff(d);
          console.log("Changes", changes);
          const pathNoName = node.path.split("/").slice(0, -1).join("/");
          sendChanges(
            project_name,
            node.name,
            pathNoName !== "" ? pathNoName : "/",
            changes
          );
          node.content = node.dirtyContent;
        } else if (node.children) {
          findChanges(node.children, project_name);
        }
      });
    };

    findChanges(fileStructureRef.current, project_name);
  }, 2000);

  const updateFileStructure = useCallback(
    (path, newContent) => {
      setFileStructure((prevStructure) => {
        let newStructure = JSON.parse(JSON.stringify(prevStructure));

        const updateNode = (nodes) => {
          nodes.forEach((node) => {
            if (node.path === path) {
              console.log("Updating node", node.name);
              console.log("Old content", node.content);
              console.log("New content", newContent);
              node.dirtyContent = newContent;
            } else if (node.children) {
              updateNode(node.children);
            }
          });
        };

        updateNode(newStructure);
        return newStructure;
      });

      // Call debounced function to send update to the server
      sendUpdateToServer(projectName);
    },
    [projectName] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // The value provided to the context consumers
  const contextValue = {
    fileStructure,
    setFileStructure,
    projectName,
    setProjectName,
    updateFileStructure,
  };

  return (
    <FileStructureContext.Provider value={contextValue}>
      {children}
    </FileStructureContext.Provider>
  );
};
