import React from "react";

// A popup message that appears on the screen

interface PopupMessageProps {
  isOpen: boolean;
  message: string;
  closePopup: () => void;
}

const PopupMessage: React.FC<PopupMessageProps> = ({
  isOpen,
  message,
  closePopup,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup-message-outer">
      <div className="popup-message-inner">
        <h1>{message}</h1>
        <button onClick={closePopup}>Close</button>
      </div>
    </div>
  );
};

export default PopupMessage;
