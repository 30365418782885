import React, { useState, createContext, useContext } from "react";

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   checkAuthStatus();
  // }, []);

  // const checkAuthStatus = async () => {
  //   console.log("Checking authentication status");
  //   try {
  //     const response = await fetch(
  //       "https://backend.chipworks.app/customerapi/auth/status/",
  //       {
  //         credentials: "include",
  //       }
  //     );
  //     if (response.ok) {
  //       console.log("User is authenticated");
  //       const data = await response.json();
  //       setIsAuthenticated(data.is_authenticated);
  //       return data.is_authenticated;
  //     } else {
  //       console.log("User is not authenticated");
  //       setIsAuthenticated(false);
  //     }
  //   } catch (error) {
  //     console.error("Error checking authentication status", error);
  //     setIsAuthenticated(false);
  //   }
  //   return false;
  // };

  const getCookie = (name) => {
    let cookieValue = "";
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        getCookie,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
