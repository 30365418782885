import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";

const PasswordResetPage: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const { email } = useParams<{ email: string }>();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetErrors, setResetErrors] = useState({});

  const handlePasswordReset = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setResetErrors({}); // Clear any previous errors

    if (newPassword !== confirmPassword) {
      setResetErrors({ confirmPassword: ["Passwords do not match!"] });
      return;
    }

    console.log("Resetting password...");
    console.log(`Token: ${token}`);

    try {
      const response = await fetch(
        "https://backend.chipworks.app/customerapi/auth/password-reset-confirm/",
        {
          method: "POST",
          body: JSON.stringify({ token, email, password: newPassword }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        const text = await response.text();
        console.log(text);
      } else {
        alert("Your password has been reset successfully.");
        navigate("/login"); // Redirect to login page
      }
    } catch (error) {
      alert("Error resetting password. Please try again.");
    }
  };

  return (
    <div
      className="login-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <h2>Reset Your Password</h2>
      <form
        onSubmit={handlePasswordReset}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <input
          type="password"
          autoComplete="new-password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <input
          type="password"
          autoComplete="new-password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        {Object.entries(resetErrors).map(([field, errors]) => (
          <div key={field}>
            {errors.map((error, index) => (
              <div key={index} style={{ color: "red" }}>
                {error}
              </div>
            ))}
          </div>
        ))}
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default PasswordResetPage;
