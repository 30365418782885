import React from "react";
import "../../index.css";

const Footer: React.FC = () => {
  return (
    <footer>
      <p>© {new Date().getFullYear()} ChipWorks</p>
      <p>
        Contact:{" "}
        <a href="mailto: chipworks.us@gmail.com">chipworks.us@gmail.com</a>
        {" | "}
        <a href="https://forms.gle/aeUae5oGYEniP3ek9">Feedback/Bug Form</a>
      </p>
    </footer>
  );
};

export default Footer;
