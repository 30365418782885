import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../authentication.tsx";

const FileUploadPopup: React.FC = ({
  onClose,
  onUploadComplete,
  projectName,
}) => {
  const { getCookie } = useAuth();

  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");

  const addFiles = (newFiles) => {
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes
    const maxFiles = 20;

    // Only accept files like .v, .sv, .txt, .json, .cfc, .py, etc
    const allowedFileExtensions = [
      ".v",
      ".sv",
      ".txt",
      ".json",
      ".cfc",
      ".py",
      ".sdc",
      ".cells",
      ".cfg",
      ".csv",
    ];

    if (files.length + newFiles.length > maxFiles) {
      setError(`Cannot upload more than ${maxFiles} files at a time.`);
      return;
    }

    for (let file of newFiles) {
      console.log("Adding file:", file);

      // Extract file extension and check if it's allowed
      const fileExtension = file.name
        .slice(file.name.lastIndexOf("."))
        .toLowerCase();
      if (!allowedFileExtensions.includes(fileExtension)) {
        setError(
          `File type not allowed for '${
            file.name
          }'. Allowed types: ${allowedFileExtensions.join(", ")}`
        );
        return;
      }

      if (file.size > maxSize) {
        setError(`File ${file.name} exceeds the 1MB limit.`);
        return;
      }
    }
    setFiles([...files, ...newFiles]);
    setError(""); // Clear error if all files are within limits
  };

  const handleFileChange = (event) => {
    addFiles(event.target.files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    addFiles(event.dataTransfer.files);
  };

  const handleUpload = async () => {
    for (let file of files) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_name", file.name);
      if (file.webkitRelativePath !== "") {
        formData.append("file_path", "/" + file.webkitRelativePath);
      } else {
        formData.append("file_path", "/");
      }
      formData.append("project", projectName);

      try {
        const response = await fetch(
          "https://backend.chipworks.app/customerapi/projects/upload/",
          {
            method: "POST",
            headers: {
              "X-CSRFToken": getCookie("csrftoken"),
            },
            credentials: "include",
            body: formData,
          }
        );

        if (!response.ok) {
          const data = await response.json();
          if (data.message) {
            setError(data.message);
          }
        } else {
          onUploadComplete(files);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div className="file-upload-popup">
      <div
        className="file-upload-content"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        style={{ color: "black" }}
      >
        {/* Only accept files like .v, .sv, .txt, .json, .cfc, .py, etc */}
        <input
          type="file"
          accept=".v,.sv,.txt,.json,.cfc,.py"
          multiple
          onChange={handleFileChange}
          style={{ marginBottom: "10px" }}
        />
        {files.length === 0 && (
          <p>
            <FontAwesomeIcon
              icon={faCloudArrowUp}
              style={{ marginRight: "5px" }}
            />
            Drop files here
          </p>
        )}
        {error && <div style={{ color: "red" }}>{error}</div>}
        {files.map((file, index) => (
          <div key={index}>{file.name}</div>
        ))}
        <div
          className="name-input-buttons"
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <button onClick={handleUpload} style={{ fontWeight: "bold" }}>
            Upload Files
          </button>
          <button onClick={onClose} style={{ fontWeight: "bold" }}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUploadPopup;
