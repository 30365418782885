import React, { useState, useEffect, useRef } from "react";
import Prism from "prismjs";
import DOMPurify from "dompurify";
import "prismjs/themes/prism-okaidia.css"; // Import PrismJS theme

import "./AIMessagingApp.css"; // Importing CSS file for styles
import "../../index.css"; // Importing global styles
import { useAuth } from "../../authentication.tsx";

interface Message {
  author: "You" | "Chipper";
  text: string;
}

const AIMessagingApp: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [userInput, setUserInput] = useState<string>("");
  const codeBlocksRef = useRef<HTMLElement[]>([]);
  const [disableEnter, setDisableEnter] = useState<boolean>(false);
  const [threadId, setThreadId] = useState<string>("");

  const { getCookie } = useAuth();

  const loadPrismLanguage = async (language: string) => {
    if (!Prism.languages[language]) {
      try {
        await import(`prismjs/components/prism-${language}.js`);
      } catch (e) {
        console.error(`Error loading the Prism language for: ${language}`, e);
      }
    }
  };

  useEffect(() => {
    // Highlight all code blocks
    messages.forEach(async (msg, index) => {
      const codeBlock = codeBlocksRef.current[index];
      if (codeBlock) {
        const language = codeBlock.className.split("-")[1];
        if (language) {
          await loadPrismLanguage(language);
          Prism.highlightElement(codeBlock);
        }
      }
    });
  }, [messages]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  const sendMessage = async () => {
    if (!userInput.trim()) return;

    setDisableEnter(true);
    setUserInput("");

    const userMessage: Message = { author: "You", text: userInput };
    setMessages([...messages, userMessage]);

    try {
      const response = await fetch(
        "https://backend.chipworks.app/customerapi/assistant/chat/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify({ message: userInput, thread_id: threadId }),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      const aiMessage: Message = {
        author: "Chipper",
        text: data.assistant_response,
      };
      setThreadId(data.thread_id);
      setMessages((msgs) => [...msgs, aiMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
    }

    setDisableEnter(false);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !disableEnter) {
      sendMessage();
    }
  };

  // Function to handle the copy to clipboard
  const copyToClipboard = (codeContent: string) => {
    navigator.clipboard.writeText(codeContent).then(
      () => {
        alert("Code copied to clipboard!"); // Or implement a more subtle notification
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  const renderMessageContent = (text: string, index: number) => {
    const regex = /```(.*?)```/gs;
    let parts = text.split(regex);

    return parts.map((part, i) => {
      if (i % 2 === 1) {
        let firstNewLine = part.indexOf("\n");
        let language =
          firstNewLine !== -1 ? part.substring(0, firstNewLine).trim() : "none";
        let codeContent =
          firstNewLine !== -1 ? part.substring(firstNewLine + 1) : part;
        const sanitizedContent = DOMPurify.sanitize(codeContent);

        return (
          <div key={`${index}-${i}`}>
            <button
              className="copy-button"
              onClick={() => copyToClipboard(codeContent)}
            >
              Copy
            </button>
            <pre>
              <code
                ref={(el) => (codeBlocksRef.current[index] = el as HTMLElement)}
                className={`language-${language}`}
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
              ></code>
            </pre>
          </div>
        );
      }
      return <span key={`${index}-${i}`}>{part}</span>;
    });
  };

  return (
    <div className="chat-container">
      <div className="messages-container">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.author.toLowerCase()}`}>
            <div className="message-identifier">
              {msg.author === "Chipper" ? (
                <img
                  src="/chipper-expanded.png"
                  alt="Chipper"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    objectFit: "contain",
                    backgroundColor: "#eee",
                    border: "1px solid #ddd",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <img
                  src="/logo192.png"
                  alt="User"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    objectFit: "contain",
                    backgroundColor: "#eee",
                    border: "1px solid #ddd",
                    marginRight: "10px",
                  }}
                />
              )}
              <div className="message-author">{msg.author}</div>
            </div>
            <div className="message-content">
              {renderMessageContent(msg.text, index)}
            </div>
          </div>
        ))}
      </div>
      <div className="input-area">
        <input
          type="text"
          placeholder="Type a message..."
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className="input-field"
        />
        <button
          onClick={sendMessage}
          className="send-button"
          disabled={disableEnter}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default AIMessagingApp;
